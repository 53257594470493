/* eslint-disable max-len */
import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import QRCode from 'qrcode-svg';
import { createConsumer, Subscription } from '@rails/actioncable';
import Navbar from '../shared/Navbar';
import Button from '../shared/Button';
import Tag from '../shared/Tag';
import src from './images/bagels.webp';
import Alert from '../shared/Alert';
import PromiseResolver from '../shared/PromiseResolver';

export default function Root() {
  const [purchasing, setPurchasing] = useState(false);
  const [content, setContent] = useState<string>();
  const [alertMessage, setAlertMessage] = useState<string>();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const closeDialog = useCallback(() => {
    dialogRef.current?.close('cancel');
  }, []);

  const onClick = useCallback(() => {
    setPurchasing(true);

    const virtualAccountPromise = new PromiseResolver<string>();
    const subscriptionPromise = new PromiseResolver<void>();

    let subscription: Subscription;
    const consumer = createConsumer();
    const cleanup = () => {
      subscription.unsubscribe();
      consumer.disconnect();
    };

    const onClose = () => {
      if (dialogRef.current?.returnValue === 'cancel') {
        subscriptionPromise.reject();
        cleanup();
      }
    };
    subscription = consumer.subscriptions.create({ channel: 'PaymentChannel' }, {
      received(response: Record<string, string>) {
        if (response.virtual_account) {
          virtualAccountPromise.resolve(response.virtual_account);
        } else {
          setAlertMessage(`Thank you for your purchase, we have received payment ${response.end_to_end_id}.`);
          subscriptionPromise.resolve();
          cleanup();
        }
      },
    });

    const showModal = async () => {
      dialogRef.current?.addEventListener('close', onClose);
      dialogRef.current?.showModal();
      try {
        await subscriptionPromise.promise;
        dialogRef.current?.close('success');
      } catch (e) {
        if (dialogRef.current?.open) {
          throw e;
        }
      } finally {
        dialogRef.current?.removeEventListener('close', onClose);
      }
    };

    const handlePayment = async () => {
      const virtualAccount = await virtualAccountPromise.promise;

      const code = Tag.toString([
        Tag.fromString('00', '01'),
        Tag.fromString('01', '12'),
        Tag.fromTagArray('26', [
          Tag.fromString('00', 'app.modernwallet'),
          Tag.fromString('01', '071000301'),
          Tag.fromString('02', virtualAccount),
        ]),
        Tag.fromString('52', '5462'),
        Tag.fromString('53', '840'),
        Tag.fromString('54', '100.00'),
        Tag.fromString('58', 'US'),
        Tag.fromString('59', 'Modern Bagelry'),
        Tag.fromString('60', 'San Francisco'),
        Tag.fromTagArray('62', [
          Tag.fromString('09', 'AE'),
        ]),
      ]);

      setContent(code);

      if ('PaymentRequest' in window) {
        const paymentRequest = new PaymentRequest(
          [
            {
              supportedMethods: window.gon.payment_handler,
              data: {
                code,
              },
            },
          ],
          {
            total: {
              label: '3 Dozen Bagels',
              amount: {
                currency: 'USD',
                value: '100.00',
              },
            },
          },
        );

        const canMakePayment = await paymentRequest.canMakePayment();
        if (canMakePayment) {
          let response;
          try {
            response = await paymentRequest.show();
            await subscriptionPromise.promise;
            await response.complete('success');
          } catch {
            await response?.complete('fail');
          }
        } else {
          await showModal();
        }
      } else {
        await showModal();
      }
    };
    handlePayment()
      .catch((error) => { console.error(`handlePayment() failed [${error}]`); })
      .finally(() => { setPurchasing(false); });
  }, []);

  const svg = useMemo(() => {
    if (content == null) {
      return '';
    }

    const qrcode = new QRCode({
      content,
      padding: 0,
      background: 'transparent',
      join: true,
      ecl: 'H',
    });
    return qrcode.svg();
  }, [content]);

  return (
    <>
      <Navbar />
      <dialog ref={dialogRef} className="bg-transparent w-full tablet:max-w-md backdrop:bg-black backdrop:bg-opacity-50">
        <div className="relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding border border-black border-opacity-20 outline-0 rounded-lg">
          <div className="flex flex-shrink-0 items-center justify-between p-4 border-b border-b-zinc-200">
            <span className="mb-0 text-xl">
              Scan QR Code
            </span>
            <button onClick={closeDialog} className="mt-2 mr-2 mb-2 ml-auto box-content w-4 h-4 text-black border-0 bg-transparent bg-center bg-close bg-auto bg-no-repeat rounded-md opacity-50" type="button" aria-label="Close" />
          </div>
          <div className="relative flex-auto p-4 mx-auto" dangerouslySetInnerHTML={{ __html: svg }} />
          <div className="flex flex-shrink-0 flex-wrap items-center justify-end border-t p-3 border-t-zinc-200">
            <Button onClick={closeDialog}>Close</Button>
          </div>
        </div>
      </dialog>
      <div className="w-full mx-auto px-3 mt-3 tablet:max-w-md">
        {alertMessage != null && <Alert message={alertMessage} />}
        <div className="relative flex flex-col min-w-0 text-black dark:text-zinc-200 break-words border border-black border-opacity-20 dark:border-white dark:border-opacity-20 bg-white dark:bg-neutral-800 bg-clip-border rounded-md">
          <img className="w-ful rounded-t-[calc(0.375rem-1px)] aspect-square" alt="A dozen bagels" src={src} />
          <div className="flex-auto p-4">
            <h5 className="mb-2 text-xl">3 Dozen Bagels</h5>
            <h6 className="text-neutral-800 dark:text-zinc-200 opacity-75 mb-2 -mt-1">$100.00 USD</h6>
            <p className="mb-3">New York style bagels baked the way bagels were supposed to be made, with the highest quality all natural ingredients and lots of love. Modern Bagelry Bagels are proofed, boiled and bathed in cold water and then baked on wood boards in a revolving tray oven.</p>
            <Button disabled={purchasing} onClick={onClick}>{purchasing ? 'Purchasing…' : 'Purchase'}</Button>
          </div>
        </div>
      </div>
    </>
  );
}
